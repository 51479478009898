@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url('assets/Inter.otf') format('opentype');
}

@font-face {
  font-family: InterBold;
  src: url('assets/Inter-Bold.otf') format('opentype');
}

@font-face {
  font-family: InterSemi;
  src: url('assets/Inter-SemiBold.otf') format('opentype');
}

html {
  font-family: Inter;
  font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on, 'ss01' on;
  font-size: 14px;
  color: #12141d;
}

.card-with-shadow {
  @apply rounded shadow;
}

.tooltip-overrides {
  max-width: 300px !important;
  background: #ffffff !important;
  border: 1px solid #f7f8f8 !important;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
  color: black !important;
  text-align: left;
  @apply p-2 font-normal text-sm;
}
